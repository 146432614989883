































































































































































































































import AstCheckbox from '@components/Checkbox.vue'
import EmailSelect from '@components/EmailSelect.vue'
// import ReportColorSettings from '@components/ReportColorSettings.vue' // TODO: We will move this back until Thick Client syncronizes correctly the color
import state, { reportService } from '@services/reportService'
import { openNewReportTemplateDlg } from '@reporting/dialogs/NewReportTemplateDlg.vue'
import { TemplateSetStatus } from '@reporting/classes'
import TemplateSet from '@reporting/components/TemplateSet.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import consultations from '@services/consultationService'
import { userData } from '@services/userData'
import { openAnalyzeReports } from '@dialogs/AnalyzeReports.vue'
import { mapGetters } from 'vuex'

export default {
	name: 'TeleconsultationsSettingView',
	components: {
		AstCheckbox,
		EmailSelect,
		TemplateSet,
		// ReportColorSettings, // TODO: We will move this back until Thick Client syncronizes correctly the color
	},
	data() {
		return {
			sets: [],
			reporting: false,
			state,
			showRetired: false,
			isLoadingConsultant: true,
			isLoadingUser: true,
			consultant: null,
			consultantList: [],
			isAdmin: false,
			hasGroupSchedule: false,
			hasResidents: false,
			userSettings: {
				emailNotification: false,
				emailResponseNotification: false,
				emailAddress: [],
				emailAddressOnCall: [],
			},
			// @ts-ignore
			isOmniUser: false,
		}
	},
	computed: {
		...mapGetters(['isConsultantUser']),
		filteredSets() {
			return this.sets.filter(s => this.showRetired || s.status !== TemplateSetStatus.Retired)
		},
		publishedSet() {
			return this.filteredSets.find(s => s.status === TemplateSetStatus.Published || s.isLegacy)
		},
		isAdvancedReportsEnabled() {
			return userData.claims.isAdvancedReportsEnabled
		},
	},
	watch: {
		async consultant() {
			this.loadConsultantTemplateSets()
			this.hasResidents = this.consultant.groupsMembers && this.consultant.groupsMembers.some(m => m.type === 2)
		},
	},
	async mounted() {
		this.getUserSettings()
		const { claims } = this.$store.state.auth
		this.isAdmin = userData.permissions.serverAdministration
		this.isOmniUser = !claims.isCommunityUser && !claims.isRepositoryUser
		if (this.isAdmin) {
			this.isLoadingConsultant = true
			this.consultantList = this.$store.state.static.consultantsForAdmin
			if (reportService.set && reportService.set.consultantId) {
				this.consultant = this.consultantList.find(c => c.id === reportService.set.consultantId)
			}
			if (!this.consultant && this.consultantList.length) {
				this.consultant = this.consultantList[0]
			}
			if (this.isConsultantUser) {
				const consultant = await consultations.getConsultantInfo(userData.claims.userId)
				this.hasGroupSchedule = consultant.hasGroupSchedule
			}
			this.isLoadingConsultant = false
		} else {
			this.consultant = await consultations.getConsultantInfo(userData.claims.userId)
			this.hasGroupSchedule = this.consultant.hasGroupSchedule
		}
	},
	methods: {
		openAnalyzeReports,
		async getUserSettings() {
			this.isLoadingUser = true
			try {
				const getUserSetting = name => this.$api.user.getUserSetting(name, 'Telemedicine')
				const getEmailNotification = getUserSetting('EmailNotification').then(
					r => (this.userSettings.emailNotification = r.data.toString().toLowerCase() === 'true')
				)
				const getEmailResponseNotification = getUserSetting('EmailResponseNotification').then(
					r => (this.userSettings.emailResponseNotification = r.data.toString().toLowerCase() === 'true')
				)
				const getEmailAddress = getUserSetting('EmailAddress').then(
					r => r.data && (this.userSettings.emailAddress = r.data.split(',').filter(x => !!x.trim()))
				)
				const getEmailAddressOnCall = getUserSetting('EmailAddressOnCall').then(
					r => r.data && (this.userSettings.emailAddressOnCall = r.data.split(',').filter(x => !!x.trim()))
				)
				await Promise.all([getEmailNotification, getEmailResponseNotification, getEmailAddress, getEmailAddressOnCall])
			} finally {
				this.isLoadingUser = false
			}
		},
		saveEmailNotification() {
			this.$api.user.setUserSetting('EmailNotification', 'Telemedicine', this.userSettings.emailNotification.toString())
		},
		saveEmailResponseNotification() {
			this.$api.user.setUserSetting(
				'EmailResponseNotification',
				'Telemedicine',
				this.userSettings.emailResponseNotification.toString()
			)
		},
		saveEmailAddress() {
			this.$api.user.setUserSetting('EmailAddress', 'Telemedicine', this.userSettings.emailAddress.join(','))
		},
		saveEmailAddressOnCall() {
			this.$api.user.setUserSetting(
				'EmailAddressOnCall',
				'Telemedicine',
				this.userSettings.emailAddressOnCall.join(',')
			)
		},
		async loadConsultantTemplateSets() {
			try {
				this.isLoadingConsultant = true
				const sets = await state.getTemplateSets(false, this.consultant.id)
				if (sets) this.sets = sets
			} finally {
				this.isLoadingConsultant = false
			}
		},
		async newWorkflow() {
			let setid = await openNewReportTemplateDlg('Teleconsultation', this.consultant.id)
			if (setid) {
				this.$router.push({
					name: 'report-editor',
					params: { setId: setid },
				})
			}
		},
		async deleteWorkflow(set: ITemplateSet) {
			let confirmed = await showConfirm('Are you sure you want to delete this workflow?')
			if (confirmed) {
				state.setTemplateSetStatus(set, TemplateSetStatus.Deleted)
				this.sets.splice(this.sets.indexOf(set), 1)
			}
		},
		async disableReporting() {
			const isConfirmed = await showConfirm(
				'<strong>Are you sure you want to disable teleconsultation reporting?</strong> You will no longer receive any reports, nor will you be able to access any previously created reports.'
			)
			if (!isConfirmed) return
			this.consultant.isImageOnly = true
			this.saveConsultantFlags()
		},
		saveConsultantFlags() {
			state.saveConsultantFlags(
				this.consultant.isImageOnly,
				this.consultant.isImageOptional,
				this.consultant.isMultipleResidentWorkflow,
				this.consultant.forceConsultantHeader,
				this.consultant.id
			)
		},
	},
}
